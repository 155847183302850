import React from 'react';
import styled from 'styled-components';

import Flex, { FlexItem } from 'styled-flex-component';
import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import Link from '../components/Link';

const ReserveButton = styled(Flex)`
  height: 80px;
  width: 250px;
  margin: 10px;
  border-radius: 6px;
  border: solid 4px #4abaa7;
  background-color: #4ab8ba;
  box-sizing: border-box;
`;
const ReserveButtonCl = styled(Flex)`
  height: 80px;
  width: 250px;
  margin: 10px;
  border-radius: 6px;
  border: solid 4px #0f79d1;
  background-color: #0f79d1;
  box-sizing: border-box;
`;

const WhiteText = styled.div`
  font-size: 18px;
  color: #ffffff;
`;

const Reservation = () => (
  <SubLayout topTitle="web予約">
    <P
      css={`
        text-align: center;
      `}
    >
      院長以外の外来診察と物理療法のWeb予約ができるようになりました。
      <br />
      下記のボタンより予約ページに移動します。
      <br />
    </P>
    <Flex
      wrap="true"
      justifyCenter
      css={`
        margin-bottom: 10px;
      `}
    >
      <FlexItem>
        <Link to="https://quiq-0334003288.peernist.co.jp/WT" external>
          <ReserveButtonCl center>
            <WhiteText>外来予約</WhiteText>
          </ReserveButtonCl>
        </Link>
      </FlexItem>
      <FlexItem>
        <Link to="https://reserva.be/hirahatacovidtest" external>
          <ReserveButton center>
            <WhiteText>物理療法の予約</WhiteText>
          </ReserveButton>
        </Link>
      </FlexItem>
    </Flex>
  </SubLayout>
);

export default Reservation;
